import React from "react";
import {
    CreateButton,
    Datagrid,
    FunctionField, ShowButton,
    TextField,
    TopToolbar,
} from "react-admin";
import ListMod from "../../../Component/Redesign/ListMod";
import DateFieldMod from "../../../Component/Redesign/DateFieldMod";
import {makeStyles} from "tss-react/mui";
import {apiKeyAsterisks} from "../../../Utils/helpers/apiKeyAsterisc";

const useStyles = makeStyles()((_) => ({
    nameWrap: {
        display: 'block',
        maxWidth: '240px',
        wordBreak: 'break-word'
    }
}));

const CustomActions = () => {
    return (
        <TopToolbar>
            <CreateButton/>
        </TopToolbar>
    );
}

export const PartnersSettings = () => {
    const { classes } = useStyles();

    return <ListMod
        title={"Partners"}
        sort={{field: "name", order: "ASC"}}
        actions={<CustomActions/>}
        resource="configs/partner"
        sx={{
            marginTop: 3,
        }}
    >
        <Datagrid bulkActionButtons={false}>
            <FunctionField
                label="Name"
                sortBy="name"
                render={(record) => <span className={classes.nameWrap}>{record.name}</span>}
            />
            <DateFieldMod showTime label="Created at" source="createdAt"/>
            <DateFieldMod showTime label="Updated at" source="updatedAt"/>
            <DateFieldMod showTime label="Api Key Expired" source="apiKeyExpired"/>
            <TextField label="Status" source="statusName"/>
            <ShowButton/>
        </Datagrid>
    </ListMod>
};
