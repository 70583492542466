import React from 'react';
import {
	Button,
	Datagrid,
	EditButton,
	FunctionField,
	LoadingPage,
	ReferenceManyField,
	RefreshIconButton,
	SelectField,
	Show,
	ShowButton,
	SimpleShowLayout,
	TabbedShowLayout,
	TabbedShowLayoutTabs,
	TextField,
	TopToolbar,
	useDelete,
	useNotify,
	usePermissions,
	useRecordContext,
	useRefresh,
	useUpdate,
} from 'react-admin';
import {
	CardStatusSelection,
	CreatorType,
	DefaultCurrency,
	Gender,
	HolderStatusSelection,
	ReferralStatusSelection,
	USER_STATUS_TYPE
} from '../../Utils/constant/Const';
import DateFieldMod from '../../Component/Redesign/DateFieldMod';
import UrlField from '../../Component/Redesign/UrlField';
import PaginationMod from '../../Component/Redesign/PaginationMod';
import {FinancialAccountByUserShow} from '../FinancialAccount/showByUser';
import {getHighNoteOrganizationLink, getHighNoteProductId} from '../../Utils/constant/HighNote';
import {makeStyles} from 'tss-react/mui';
import {RepaymentsReferenceList} from '../Repayments/reference-list';
import {ENV} from '../../Const/ProjectEnv';
import {hasAdminRole} from '../../Utils/helpers/permissions';
import {reformatNumber} from '../../Utils/helpers/reformat-number';
import {ShowSubscriptionByUser} from "../Subscription/ShowByUser";
import SocialChannelsByUser from "../SocialChannels/SocialChannelsByUser";
import ImageFieldMod from "../../Component/Redesign/ImageFieldMod";
import {HelixApplicationTab} from "../../Component/UserTabs/HelixApplicationTab";

const useStyles = makeStyles()({
	toolbar: {
		alignItems: 'center',
	},
	item: {
		display: 'flex',
		marginRight: '15px'
	},
	total: {
		fontSize: 20,
		lineHeight: 2.63,
		color: '#3f51b5',
	},
	text: {
		fontSize: 20,
		color: '#3f51b5',
		padding: '12px 5px'
	}
});

const KillBank = () => {
	const record = useRecordContext()
	const refresh = useRefresh()
	const notify = useNotify()

	const [kill] = useUpdate(
		'accounts/invalidate',
		{id: record?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh()
			},
			onError: (err) => {
				notify(err.message, {type: 'error'})
			}
		}
	);


	return <Button label="Invalidate Bank" onClick={() => kill()}/>

}
const PostShowActions = () => {
	const record = useRecordContext();
	const {permissions, isLoading} = usePermissions();
	const {classes} = useStyles();
	const refresh = useRefresh();
	const [updateBalance] = useUpdate(
		'users/update',
		{id: record?.id || '', data: {}},
		{
			onSuccess: () => {
				refresh()
			},
		}
	);

	if (!record?.user) {
		return null;
	}
	return (
		<TopToolbar className={classes.toolbar}>
			<div className={classes.item}>
				<div className={classes.text}> Referral bonus,{DefaultCurrency.badge}:</div>
				{
					(record.user?.referralBonus) ?
						<FunctionField className={classes.total}
													 render={record => reformatNumber(record.user.referralBonus)}/> :
						<div className={classes.text}> - </div>
				}
			</div>
			<div className={classes.item}>
				<div className={classes.text}> Total transactions,{DefaultCurrency.badge}:</div>
				{
					(record.user?.transactions) ?
						<FunctionField className={classes.total}
													 render={record => reformatNumber(record.user.transactions)}/> :
						<div className={classes.text}> - </div>
				}
			</div>
			<div className={classes.item}>
				<div className={classes.text}> Total balance,{DefaultCurrency.badge}:</div>
				{
					(record.user?.balance) ?
						<FunctionField className={classes.total}
													 render={record => reformatNumber(record.user.balance)}/> :
						<div className={classes.text}> - </div>
				}
			</div>
			<div className={classes.item}>
				<div className={classes.text}> Total income,{DefaultCurrency.badge}:</div>
				{
					(record.user?.income) ?
						<FunctionField className={classes.total}
													 render={record => reformatNumber(record.user.income)}/> :
						(<div className={classes.text}> - </div>)
				}
			</div>
			<RefreshIconButton color="primary" onClick={() => updateBalance()}> </RefreshIconButton>
			{
				hasAdminRole(permissions) && !isLoading && record.user?.status !== USER_STATUS_TYPE.deleted ?
					<EditButton/> :
					null
			}
		</TopToolbar>
	)
};

export const UserTabbedShow = () => {
	const record = useRecordContext();
	const {permissions, isLoading} = usePermissions();
	const higNoteLink = getHighNoteOrganizationLink();
	const higNoteProductId = getHighNoteProductId();
	const [removeImage] = useDelete()
	const notify = useNotify()
	const refresh = useRefresh()

	const onRemoveUserAvatar = () => {
		removeImage('users/image',
			{id: record?.id},
			{
				onSuccess: () => {
					notify('Image removed')
					refresh()
				},
				onError: (err) => {
					notify(err.message, {type: 'error'})
				}
			})
	}

	return !isLoading ?
		<TabbedShowLayout tabs={<TabbedShowLayoutTabs variant="scrollable" scrollButtons="auto"/>}>
			{
				hasAdminRole(permissions) &&
				<TabbedShowLayout.Tab label="User">
					<SimpleShowLayout>
						<TextField label="Id" source="user.id" sortBy="id"/>
						<FunctionField label="Avatar" render={() =>
							<ImageFieldMod source="user.s3Url"
														 onRemove={onRemoveUserAvatar}
														 buttonSx={{
															 position: 'absolute',
															 right: '-20px',
															 top: '0',
															 minWidth: '40px',
															 '& .MuiButton-startIcon': {
																 marginRight: '0'
															 }
														 }}
														 imageSx={{
															 '& .RaImageField-image': {
																 maxWidth: '100px',
																 maxHeight: '100px',
																 borderRadius: '50%'
															 }
														 }}/>
						}/>
						<TextField label="Phone" source="user.phone"/>
						<TextField label="First Name" source="user.firstName"/>
						<TextField label="Last Name" source="user.lastName"/>
						<TextField label="Email" source="user.email"/>
						<SelectField label="Gender" source="user.gender" choices={Gender}/>
						<SelectField label="Creator Type" source="user.creatorType" choices={CreatorType}/>
						<DateFieldMod showTime label="Created At" source="user.createdAt"/>
					</SimpleShowLayout>
				</TabbedShowLayout.Tab>
			}
			{/*<TabbedShowLayout.Tab label="Teams">*/}
			{/*	<TextField label="#" source="id"/>*/}
			{/*</TabbedShowLayout.Tab>*/}
			{
				hasAdminRole(permissions) &&
				<TabbedShowLayout.Tab label="Social channels">
					<SocialChannelsByUser/>
				</TabbedShowLayout.Tab>
			}
			<TabbedShowLayout.Tab label="Referrals">
				<ReferenceManyField source="id"
														reference={`users/${record?.id}/referrals`}
														target="user_id"
														label={false}
														pagination={<PaginationMod/>}>
					<Datagrid bulkActionButtons={false}>
						<TextField label="Id" source="id"/>
						<TextField label="Email" source="email"/>
						<TextField label="Referral code" source="referral_code"/>
						<DateFieldMod showTime label="Last Updated" source="updated_at"/>
						<TextField label="Active Days" source="active_days"/>
						<SelectField label="Status" source="status" choices={ReferralStatusSelection}/>
						<FunctionField label="Available balance"
													 sortBy="amount"
													 render={record => `${DefaultCurrency.badge}${reformatNumber(record.amount)}`}/>
					</Datagrid>
				</ReferenceManyField>
			</TabbedShowLayout.Tab>
			<TabbedShowLayout.Tab label="Membership" disabled={!record?.user?.activeSubscription}>
				<ShowSubscriptionByUser id={record?.id}/>
			</TabbedShowLayout.Tab>
			{
				hasAdminRole(permissions) &&
				<TabbedShowLayout.Tab label="Bank Accounts">
					<ReferenceManyField source="id"
															label={false}
															target="user_id"
															reference="accounts"
															pagination={<PaginationMod/>}
															emptyText="No results found">
						<Datagrid bulkActionButtons={false}>
							<TextField label="Id" source="id"/>
							<TextField label="Name" source="name"/>
							<TextField label="Bank" source="bank"/>
							<TextField label="Type" source="type"/>
							<TextField label="Subtype" source="subtype"/>
							<FunctionField label="Available balance" sortBy="available_balance"
														 render={record => `${DefaultCurrency.badge}${reformatNumber(record.available_balance)}`}/>
							<FunctionField label="Current balance" sortBy="current_balance"
														 render={record => `${DefaultCurrency.badge}${reformatNumber(record.current_balance)}`}/>
							<TextField label="Mask" source="mask"/>
							<DateFieldMod showTime label="Created At" source="created_at"/>
							<FunctionField label="Default"
														 render={bank => bank.id === record?.user?.defaultAccount ? 'YES' : 'NO'}/>
							{ENV.ENV !== 'production' &&
								<KillBank/>
							}
						</Datagrid>
					</ReferenceManyField>
				</TabbedShowLayout.Tab>
			}
			<TabbedShowLayout.Tab label="Card requests">
				<ReferenceManyField source="id" reference="holders" target="userId" label={false}
														emptyText="No results found">
					<Datagrid bulkActionButtons={false}>
						<TextField label="Id" source="id"/>
						{
							hasAdminRole(permissions) &&
							[
								<TextField key="user_name" label="User name" source="user_name"/>,
								<TextField key="name" label="Authorized person" source="name"/>,
								<TextField key="email" label="Email" source="email"/>,
							]
						}
						<TextField label="Legal business name" source="business_name"/>
						<SelectField label="Status" source="status" choices={HolderStatusSelection}/>
						<UrlField
							label="HIGHNOTE LINK"
							source="holder_id"
							val={`${higNoteLink}/account-holders/`}
							target="_blank"
							rel="noopener noreferrer"
						/>
						<ShowButton/>
					</Datagrid>
				</ReferenceManyField>
			</TabbedShowLayout.Tab>
			<TabbedShowLayout.Tab label="Cards" disabled={!record?.financialAccount}>
				{record?.financialAccount &&
					<ReferenceManyField source="financialAccount.id" reference="cards" target="financialAccountId"
															label={false} emptyText="No results found">
						<Datagrid bulkActionButtons={false}>
							<TextField label={'Id'} source="id"/>
							<TextField label={'Bin'} source="bin"/>
							<TextField label={'Last4'} source="last4"/>
							<DateFieldMod label="Expiration date" options={{year: 'numeric', month: 'numeric'}}
														source="expiration_date"/>
							<TextField label={'Network'} source="network"/>
							<SelectField label={'Status'} source="status" choices={CardStatusSelection}/>
							<UrlField
								label="HIGHNOTE LINK"
								source="external_id"
								val={`${higNoteLink}/card-products/${higNoteProductId}/payment-cards/`}
								target="_blank"
								rel="noopener noreferrer"
							/>
							<ShowButton/>
						</Datagrid>
					</ReferenceManyField>
				}
			</TabbedShowLayout.Tab>
			<TabbedShowLayout.Tab label="Financial account" disabled={!record?.financialAccount}>
				<div>
					{
						record?.financialAccount &&
						<FinancialAccountByUserShow source="financialAccount" userId={record?.id}/>
					}
				</div>
			</TabbedShowLayout.Tab>
			<TabbedShowLayout.Tab label="Repayments" disabled={!record?.financialAccount}>
				<div>
					{
						record?.financialAccount &&
						<RepaymentsReferenceList source="financialAccount.id"/>
					}
				</div>
			</TabbedShowLayout.Tab>
			<TabbedShowLayout.Tab label="Activity">
				<ReferenceManyField source="id" reference="activity" target="user_id" label={false}
														pagination={<PaginationMod/>}>
					<Datagrid bulkActionButtons={false}>
						<DateFieldMod showTime label="Date" source="datetime"/>
						<TextField label="Event" source="event_message"/>
					</Datagrid>
				</ReferenceManyField>
			</TabbedShowLayout.Tab>
			<TabbedShowLayout.Tab label="Helix Application">
				<HelixApplicationTab/>
			</TabbedShowLayout.Tab>
		</TabbedShowLayout> :
		<LoadingPage/>
}
export const UserShow = () => {
	return (
		<Show actions={<PostShowActions/>}>
			<UserTabbedShow/>
		</Show>
	)
};
