import {usePermissions} from "react-admin";
import {LoadingPage} from "ra-ui-materialui";
import React, {useEffect} from "react";
import {ROLE_ADMIN, ROLE_MANAGER} from "../../Utils/constant/Permissions";
import {useNavigate} from "react-router-dom";

export const OverviewWrapper = () => {
    const {permissions} = usePermissions();
    const navigate = useNavigate();
    const permissionsList = [ROLE_ADMIN, ROLE_MANAGER]

    useEffect(() => {
        if (permissionsList.includes(permissions?.role)) {
            navigate('/overview/market-value')
        } else {
            navigate('/overview/banking-stats')
        }
    }, [permissions])

    return <LoadingPage/>
}