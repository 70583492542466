import React, {useEffect} from "react";
import {List} from "react-admin";
import PaginationMod from "./PaginationMod";
import {useLocation} from "react-router-dom";
import {removeAllListParams} from "../../Utils/helpers/removeAllListParams";

export const ListMod = ({children, ...props}) => {
	const location = useLocation();
	const defaultSort = { field: "id", order: "DESC" };

	const sort = props.sort || defaultSort

	useEffect(() => {
		removeAllListParams()
	}, [location.pathname]);

	return (
		<List
			{...props}
			pagination={
				props.pagination !== undefined ? props.pagination : <PaginationMod/>
			}
			sort={sort}
			exporter={false}
		>
			{children}
		</List>
	);
};

export default ListMod;
