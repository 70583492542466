import {useNavigate, useParams} from "react-router-dom";
import {useGet} from "../../../Hooks/useGet";
import {useEffect, useRef, useState} from "react";
import {SimpleForm, BooleanInput, useNotify, useRefresh, Button} from "react-admin";
import dataProvider from '../../../Api/api'
import {makeStyles} from "tss-react/mui";
import {apiKeyAsterisks} from "../../../Utils/helpers/apiKeyAsterisc";

const useStyles = makeStyles()((_) => ({
    container: {
        marginTop: '32px',
        padding: '16px 32px',
        backgroundColor: '#fff',
        '-webkit-transition': 'box-shadow 300mscubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transition: 'box-shadow 300mscubic-bezier(0.4, 0, 0.2, 1) 0ms',
        borderRadius: '4px',
        boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
        overflow: 'hidden'
    },
    button: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        height: '40px',
        marginLeft: '10px',
        '& span': {
            margin: "0",
        },
    },
    buttonBack: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        height: '40px',
        marginLeft: 'auto',
        '& span': {
            margin: "0",
        },
    },
    apiKeyField: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '20px'
    },
    apiKeyText: {
        display: 'flex',
        alignItems: "center",

        margin: 0,
        padding: '0 8px',
        height: '40px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.2)',
        borderRadius: '4px 0 0 4px',
    },
    apiKeyButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        minWidth: '115px',
        height: '40px',
        '& span': {
            margin: "0",
        },
        borderRadius: '0 4px 4px 0',
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
        marginBottom: '20px',

        '& form div': {
            margin: 0,
            padding: '0 !important'
        },
        '& form label': {
            margin: 0,
            padding: '0 !important'
        },
        '& form p': {
            margin: 0,
            padding: '0 !important'
        },
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    column: {
        display: "flex",
        alignItems: "center",

        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'rgba(0, 0, 0, 0.2)',

        '& p': {
            width: '50%',
            margin: 0,
            padding: '8px',
        },

        '& p:first-child': {
            borderRightWidth: '1px',
            borderRightStyle: 'solid',
            borderRightColor: 'rgba(0, 0, 0, 0.2)',
            marginRight: 8
        },

        '& p:last-child': {
            textAlign: 'right'
        }
    }
}));

export const PartnerDetail = () => {
    const router = useParams()
    const notify = useNotify();
    const refresh = useRefresh()

    const apiKeyRef = useRef(null)

    const {data} = useGet(`configs/partner/${router.id}`)

    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    const { classes } = useStyles();

    const [status, setStatus] = useState(false)

    useEffect(() => {
        setStatus(data?.status === 1)
    }, [data])

    useEffect(() => {
        if (apiKeyRef?.current) {
            console.log(apiKeyRef)
        }
    }, [apiKeyRef])

    const copyToClipboard = (data) => {
        navigator.clipboard.writeText(data)
        notify('Copied')
    }

    const refreshApiKey = () => {
        dataProvider.post(
            `configs/partner/${router.id}/api-key`,
            {}
        ).then(() => {
            refresh()
            notify('Api Key has been updated!')
        }).catch((err) => notify(err.message, {type: 'error'}))
    }

    const handleStatusChange = async (newValue) => {
        try {
            await dataProvider.edit(`configs/partner/${router.id}/status`, {
                data: { status: newValue ? 1 : 2 },
            });
            setStatus(newValue);
            notify("Status updated successfully!");
            refresh();
        } catch (error) {
            notify("Failed to update status", { type: "error" });
        }
    };

    const parseActivities = (data) => {
        const currentMonth = new Date().toLocaleString('en-US', { month: 'short', year: 'numeric' });

        return data === currentMonth.replace(' ', '-') ? `Current (${currentMonth})` : data
    }

    const sortedActivities = (list) => {
        const currentMonth = new Date().toLocaleString('en-US', { month: 'short', year: 'numeric' });

        return list.sort((a, b) => {
            if (a.month.replace('-', ' ') === currentMonth) return -1;
            if (b.month.replace('-', ' ') === currentMonth) return 1;

            const dateA = new Date(a.month);
            const dateB = new Date(b.month);
            return dateB - dateA;
        });
    };

    return data ? <div className={classes.container}>
        <Button variant="outlined"
                color="primary"
                className={classes.buttonBack}
                onClick={goBack}>
            Back to Partners List
        </Button>
        <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-kcxgf9-MuiTypography-root">
            <span>{data?.name}</span>
        </p>
        {data?.api_id ? <div className={classes.apiKeyField}>
            <p style={{ width: apiKeyRef?.current?.offsetWidth ? `${apiKeyRef?.current?.offsetWidth}px` : 'fit-content' }} className={classes.apiKeyText}>{data.api_id}</p>
            <Button variant="contained"
                    color="primary"
                    className={classes.apiKeyButton}
                    onClick={() => copyToClipboard(data.api_id)}>
                Copy Api Id
            </Button>
        </div> : null}
        {data?.api_key ? <div className={classes.apiKeyField}>
            <p ref={apiKeyRef} className={classes.apiKeyText}>{apiKeyAsterisks(data.api_key)}</p>
            <Button variant="contained"
                    color="primary"
                    className={classes.apiKeyButton}
                    onClick={() => copyToClipboard(data.api_key)}>
                Copy Api Key
            </Button>
            <Button variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={refreshApiKey}>
                Refresh Api Key
            </Button>
        </div> : null}
        <div className={classes.flexCenter}>
            <SimpleForm toolbar={<></>}
                        sx={{'& .MuiFormControl-root ': {minWidth: '400px'}}}>
                <BooleanInput label="Status"
                              source="receiveNotification"
                              checked={status}
                              onChange={(event) => handleStatusChange(event.target.checked)}/>
            </SimpleForm>
        </div>
        <div>
            <div className={classes.column}>
                <p>Total</p>
                <p>{data?.activities?.total || 0}</p>
            </div>
            { data?.activities?.data?.length ? sortedActivities(data.activities.data).map((activity, i) => {
                return <div key={i} className={classes.column}>
                    <p>{parseActivities(activity?.month)}</p>
                    <p>{activity?.count || 0}</p>
                </div>
            }) : null }
        </div>
    </div> : null
}