import {
    TextField,
    useRecordContext,
    SimpleShowLayout,
    FunctionField,
    Button,
    Confirm,
    useNotify,
    useRefresh,
    useDataProvider
} from "react-admin";
import {makeStyles} from "tss-react/mui";
import {useGet} from "../../Hooks/useGet";
import React, {Fragment, useState} from "react";

const useStyles = makeStyles()((_) => ({
    applicationButtonWrap: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        marginBottom: '16px',
        width: '100%'
    },
    addressFiledWrap: {
        marginTop: 8
    },
    button: {
        display: "flex",
        alignItems: "center",
        justifyContent: 'center',
        height: '40px',
        marginLeft: 'auto',
        backgroundColor: '#d32f2f',
        borderColor: '#d32f2f',
        color: '#FFFFFF',
        '& span': {
            margin: "0",
        },
        '&:hover': {
            backgroundColor: '#FFFFFF',
            borderColor: '#d32f2f',
            color: '#d32f2f',
        }
    },
}));

export const HelixApplicationTab = (props) => {
    const record = useRecordContext()
    const userId = record.id

    const notify = useNotify()
    const refresh = useRefresh()

    const {data} = useGet(`helix/application/user/${userId}`)
    const dataProvider = useDataProvider();

    const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false)

    const { classes } = useStyles(props)

    const onRemoveApplication = async () => {
        dataProvider.delete(
            `helix/application/user/${userId}`,
            {}
        ).then(() => {
            refresh()
            notify('Application has been removed!')
        }).catch((err) => notify(err.message, {type: 'error'}))
    }

    return data?.registration ? <SimpleShowLayout>
        <Confirm isOpen={openDeleteConfirm}
                 title="Attention"
                 content="Are you sure that you want remove DDA Application?"
                 onConfirm={() => {
                     onRemoveApplication()
                     setOpenDeleteConfirm(false)
                 }}
                 onClose={() => setOpenDeleteConfirm(false)}/>
        <div
            className={classes.applicationButtonWrap}
        >
            <Button variant="outlined"
                    color="primary"
                    className={classes.button}
                    onClick={() => setOpenDeleteConfirm(true)}>
                Remove Application
            </Button>
        </div>
        <TextField label="Business Info:" source="" sortable={false}/>
        <FunctionField
            render={() => `${data?.business?.profile?.id}`}
            label="Id" sortable={false}/>
        <FunctionField
            render={() => data?.business?.profile?.businessLegalName}
            label="Business Legal Name" sortable={false}/>
        {data?.owners?.length ? data.owners.map((owner) => <Fragment key={owner.profile.id}>
            <hr/>
            <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-kcxgf9-MuiTypography-root">Profile Info:</p>
            <div>
                <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-kcxgf9-MuiTypography-root">
                    <span>Id</span>
                </p>
                <FunctionField
                    render={() => `${owner?.profile.id}`}
                    label="" sortable={false} />
            </div>
            <div className={classes.addressFiledWrap}>
                <p className="MuiTypography-root MuiTypography-body1 RaLabeled-label css-kcxgf9-MuiTypography-root">
                    <span>Full Name</span>
                </p>
                <FunctionField
                    render={() => `${owner?.profile.firstName} ${owner?.profile.lastName}`}
                    label={''} sortable={false}/>
            </div>
        </Fragment>) : null}
    </SimpleShowLayout> : <p>No results found</p>
}