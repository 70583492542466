import {
    regex,
    required,
    TextInput,
    Toolbar,
    useNotify,
    useRefresh,
    FormGroupsProvider,
    useCreate
} from "react-admin";
import {NAME_PATTERN} from "../../../Utils/regex/regex";
import {useNavigate} from "react-router-dom";
import Button from "@mui/material/Button";
import {Card, CardContent, CircularProgress, Stack} from "@mui/material";
import {FormProvider, useForm} from "react-hook-form";
import {styled} from "@mui/material/styles";
import {buildFormData} from "../../../Api/api";

const PartnerCreateToolbar = ({isLoading, isValidForm, isSubmitted}) => {
    const navigate = useNavigate();
    const goBack = () => navigate(-1);

    return (
        <Toolbar sx={{gap: '8px'}}>
            <Button variant="outlined"
                    color="secondary"
                    onClick={goBack}>
                Cancel
            </Button>
            <Button variant="contained"
                    type="submit"
                    color="primary"
                    disabled={isLoading || (!isValidForm && isSubmitted)}>
                {isLoading && <CircularProgress size={25} thickness={2}/>}
                Save
            </Button>
        </Toolbar>
    )
}

const StyledDivContent = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    [`& .MuiFormControl-root`]: {
        minWidth: '400px'
    }
})

export const CreatePartner = () => {
    const notify = useNotify();
    const refresh = useRefresh();
    const navigate = useNavigate();
    const [create, {isLoading}] = useCreate()

    const form = useForm({
        defaultValues: { name: '' },
        shouldFocusError: true,
        mode: 'onChange',
        reValidateMode: 'onChange',
    })

    const { formState } = form

    const onSubmitForm = ({...rest}) => {
        const formData = buildFormData(rest)

        create(
            'configs/partner',
            {
                data: formData,
                meta: {resourceSuffix: ''}
            },
            {
                onSuccess: () => {
                    notify('Partner has been successfully created!')
                    navigate(-1);
                    refresh();
                },
                onError: (err) => {
                    notify(err.message, {type: 'error'})
                }
            })
    }

    return <div
        style={{display: 'flex'}}
    >
        <Card sx={{flex: '1 1 auto'}}>
            <FormProvider {...form} >
                <FormGroupsProvider>
                    <form onSubmit={form.handleSubmit(onSubmitForm)}>
                        <CardContent>
                            <Stack sx={{alignItems: 'flex-start'}}>
                                <StyledDivContent>
                                    <TextInput label="Name"
                                               name="name"
                                               source="name"
                                               validate={[required(), regex(NAME_PATTERN, 'Please enter a valid name.')]}/></StyledDivContent>
                            </Stack>
                            <PartnerCreateToolbar
                                isLoading={isLoading}
                                isValidForm={formState.isValid}
                                isSubmitted={formState.isSubmitted}
                            />
                        </CardContent>
                    </form>
                </FormGroupsProvider>
            </FormProvider>
        </Card>
    </div>
}